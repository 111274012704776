import { HStack, VStack, Text } from "@chakra-ui/react";
import FadeInWrapper from "./fadein";
import ExpandableComponent from "./expandable";

const OurProj = () => {
  return (
    <VStack width={"100%"} align={"flex-start"} spacing={0}>
      <FadeInWrapper>
        <VStack
          alignSelf={"flex-start"}
          marginBottom={"5vh"}
          spacing={0}
          align={"flex-start"}
          gap={0}
        >
          <Text color={"#0BCF6B"} fontSize={["25px", "32px" ,"40px"]}>
            НЕКОТОРЫЕ ПРОЕКТЫ
          </Text>
          <Text color={"white"} fontSize={["25px", "32px" ,"40px"]}>
            {" "}
            С НАШИМ УЧАСТИЕМ
          </Text>
        </VStack>
      </FadeInWrapper>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Складская система"}
          text={
            "Мы разработали и внедрили информационную систему, оптимизирующую взаимодействие между складами заказчика в разных частях города, учитывая все особенности бизнес-процессов для максимальной адаптации под специфические требования компании. Система обеспечивает высокую скорость работы и масштабируемость, гарантируя стабильное функционирование при интенсивных нагрузках. Внедрение автоматических закупок упростило и ускорило процесс пополнения складских запасов, сократив издержки и минимизировав человеческий фактор. Заказчик получил надежную и эффективную систему управления складскими операциями, полностью соответствующую текущим и будущим потребностям компании."
          }
          techno={"Java Spring Boot / React / TypeScript / Redux / Docker"}
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Ecom Telegram App"}
          text={
            "Мы разработали телеграм-мини-приложение для крупного магазина наушников и аксессуаров Apple, что привело к заметному увеличению продаж и привлечению новых клиентов через эту платформу. Наше решение включает в себя интуитивно понятную систему управления контентом и скидками, доступную как через веб-интерфейс, так и напрямую через Telegram-бота, обеспечивая клиентам комфортное и выгодное пользование сервисами магазина."
          }
          techno={
            "Fast Api / Strapi / React / Telegram Api / TypeScript / MobX / Docker "
          }
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Обработка изображений"}
          text={
            "Наш последний проект — инновационное мобильное приложение для обработки фотографий, созданное с применением передовых технологий нейронных сетей. Это приложение призвано дать пользователям возможность творческой обработки изображений, особенно фотографий людей, в уникальном стиле. С его помощью можно переносить фотографии в захватывающие вселенные, такие как мир Marvel, и превращать их обладателей в супергероев, при этом полностью сохраняя визуальное сходство лица до и после обработки."
          }
          techno={
            "Fast Api / Stable Diffusion / pyTorch / diffusers / React Native / TypeScript / MobX / Docker / Express JS / Kotlin "
          }
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Приложение для колл-центров"}
          text={
            "Мы разработали инновационное приложение для колл-центров, которое внедряет передовые технологии нейронных сетей. Это приложение способно распознавать голос на видео или аудиозаписях и трансформировать его в текстовый формат. После этого с помощью генеративной языковой модели происходит анализ полученных текстов и оценка качества работы и корректности общения сотрудников колл-центра. Мы учитываем все потребности наших заказчиков, внедряя систему распознавания отдельных фраз и слов в записях, а также предоставляя возможность загрузки в модель дополнительных данных о компании колл-центра, что способствует более точной оценке знаний и профессионализма сотрудников."
          }
          techno={"LLM / LLAMA / Openai Api / Electron JS / Telegram Api  "}
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"FinTech стартап"}
          text={
            "Мы разработали веб-сайт и мини-приложение для Telegram для криптовалютного финтех-стартапа из США. Наша команда обеспечила безопасную и надежную обработку транзакций внутри веб-приложения, интегрировав автоматическую систему учета пополнений и списаний с аккаунтов пользователей. Мы также реализовали отказоустойчивую систему хранения зашифрованных данных на серверах заказчика, обеспечивая высокий уровень защиты и конфиденциальности для всех пользовательских данных."
          }
          techno={
            "Vue JS / Telegram Api / React / Java Spring Boot / Docker / Redux / Tether Api / Blockchain  "
          }
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Веб портал сети мед клиник"}
          text={
            "Разработали веб портал сети мед клиник Ульяновска. Учли пожелания заказчика и внедрили удобную систему управления контентом. Реализовали возможность добавления информационных статей и добавили блог компании."
          }
          techno={"Vue JS / Fast Api / Docker  "}
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Решение для рекламного производства"}
          text={
            "Мы разработали решение для распознавания текста на рекламных плакатах и постерах, а также его проверки для нашего заказчика. Внедрили нейросеть для распознавания текста, которая была успешно дообучена на реальных данных, предоставленных заказчиком. Также создали приложение с удобным интерфейсом, доступное для платформ Windows и macOS."
          }
          techno={"PyQT / FastApi / LLM / Tesseract "}
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Информационная панель лифтов"}
          text={
            "Мы разрабатываем программное решение для информационных панелей лифтов премиум и бизнес-класса. Эти панели обладают возможностью отображать настраиваемые виджеты с информацией, такой как погода, пробки, котировки акций, новости и многое другое. Мы создали собственный фреймворк для решения проблемы корректного отображения графики виджетов на одноплатных компьютерах архитектуры ARM. В дополнение к этому, мы внедряем нейросеть для распознавания лиц, что позволяет предоставлять пользователям лифта персонализированную рекламную информацию."
          }
          techno={
            "FastApi / React / Mobx / TypeScript / собственный фреймворк / Yolo / pyQt / Docker "
          }
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Аналитика telegram"}
          text={
            "Мы разработали решение для аналитики рекламных переходов в Telegram-каналах и группах. В рамках проекта был создан бот для сбора и анализа данных, обеспечивающий возможность получения подробной статистики по каждой проведенной рекламной кампании."
          }
          techno={"Pyrogram / Telegram Client Api / Express JS / Docker"}
        />
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
        minH={"90px"}
      >
        <ExpandableComponent
          header={"Freelance GPT"}
          text={
            "Мы разработали фриланс-биржу, предоставляющую возможность поиска и создания заказов для исполнителей. В систему интегрирована усовершенствованная языковая модель нейросети для автоматического формирования технических заданий. Кроме того, мы создали систему автоматической оценки кандидатов на соответствие требованиям заказчика перед началом выполнения заказа."
          }
          techno={"Vue JS / LLM / LLAMA / Express JS / Docker"}
        />
      </HStack>
    </VStack>
  );
};

export default OurProj;

import React, { useState } from "react";
import "./ExpandableComponent.css";
import { FaPlus, FaTimes } from "react-icons/fa";

const ExpandableComponent = ({ header, text, techno }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`expandable-container ${isExpanded ? "expanded" : ""}`}>
      <div className="header" onClick={toggleExpand}>
        <span>{header}</span>
        <div className={`icon ${isExpanded ? "rotated" : ""}`}>
          {isExpanded ? <FaTimes /> : <FaPlus />}
        </div>
      </div>
      <div className={`content ${isExpanded ? "expanded" : ""}`}>
        <p>{text}</p>
        <p style={{ color: "#0BCF6B" }}>{techno}</p>
      </div>
    </div>
  );
};

export default ExpandableComponent;

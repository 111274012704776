import { HStack, VStack, Image, Text } from "@chakra-ui/react";
import mainimage from "./../images/mainimage.jpg";
import FadeInWrapper from "./fadein";
import useWindowDimensions from "../hooks/window_dimensions";
const MainImage = () => {
  const { width, height } = useWindowDimensions();
  return (
    <HStack
      width={"100%"}
      position={"relative"}
      justify={width <= 820 ? "center" : "flex-end"}
    >
      <FadeInWrapper>
        <Image
          src={mainimage}
          width={width <= 820 ? "100vw" : "55vw"}
          height={["30vh","35vh", "38vh", "45vh", "70vh"]}
          alignSelf={"flex-end"}
          opacity={0.5}
          // filter={'blur(1px)'}
        />
      </FadeInWrapper>

      <Text
        position={"absolute"}
        top={20}
        color={"white"}
        fontSize={["30px", "45px", "60px", "80px"]}
        fontWeight={800}
        left={0}
        lineHeight={0.9}
      >
        30+
        <br />
        РАЗРАБОТЧИКОВ
        <br />
      </Text>
      <VStack position={"absolute"} bottom={width <= 993 ? 0 : 20} left={0}>
        <FadeInWrapper>
          <Text color={"white"} fontWeight={700}>
            Для решения <br />
            ваших задач. <br />{">>>"}
          </Text>
        </FadeInWrapper>
      </VStack>
    </HStack>
  );
};

export default MainImage;

import { HStack, VStack, Text, Stack } from "@chakra-ui/react";
import FadeInWrapper from "./fadein";
import ExpandableComponent from "./expandable";
import useWindowDimensions from "../hooks/window_dimensions";

const OurPodhod = () => {
  const { width, height } = useWindowDimensions();
  return (
    <VStack width={"100%"} align={"flex-start"} spacing={0}>
      <FadeInWrapper>
        <Stack alignSelf={"flex-start"} align={'flex-start'} marginBottom={"5vh"} direction={width <= 564 ? 'column': 'row'} >
          <Text color={"#0BCF6B"} fontSize={["25px", "32px" ,"40px"]}>
            НАШ
          </Text>
          <Text color={"white"} fontSize={["25px", "32px" ,"40px"]}>
            {" "}
            ПОДХОД К РАЗРАБОТКЕ
          </Text>
        </Stack>
      </FadeInWrapper>
      {width <= 1456 ? (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
          minH={"90px"}
        >
          <ExpandableComponent
            header={"#1 УСИЛИВАЕМ ВАШУ КОМАНДУ"}
            text={`Готовы взять часть ваших работ по разработке на субподряд,
            дополнить вашу команду специалистами, работать в тесной
            технологической интеграции с вашими менеджерами, разработчиками
            и другими специалистами.`}
          />
        </HStack>
      ) : (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
        >
          <FadeInWrapper>
            <HStack
              justify={"space-between"}
              width={"85vw"}
              padding={0}
              height={"100px"}
            >
              <HStack>
                <Text color={"#0BCF6B"}>#1</Text>{" "}
              </HStack>
              <HStack>
                <Text color={"white"} fontWeight={700}>
                  УСИЛИВАЕМ ВАШУ КОМАНДУ
                </Text>
              </HStack>
              <HStack>
                <Text color={"white"} width={"30vw"} fontSize={14}>
                  Готовы взять часть ваших работ по разработке на субподряд,
                  дополнить вашу команду специалистами, работать в тесной
                  технологической интеграции с вашими менеджерами,
                  разработчиками и другими специалистами.
                </Text>
              </HStack>
            </HStack>
          </FadeInWrapper>
        </HStack>
      )}
      {width <= 1456 ? (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
          minH={"90px"}
        >
          <ExpandableComponent
            header={"#2 НЕТИПОВЫЕ ПРОЕКТЫ"}
            text={`Специализируемся на реализации сложных и нетиповых проектов, где
            изначально требования до конца не ясны или могут меняться в ходе
            реализации.`}
          />
        </HStack>
      ) : (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
        >
          <FadeInWrapper>
            <HStack
              justify={"space-between"}
              width={"85vw"}
              padding={0}
              height={"100px"}
            >
              <HStack>
                <Text color={"#0BCF6B"}>#2</Text>{" "}
              </HStack>
              <HStack>
                <Text color={"white"} fontWeight={700}>
                  НЕТИПОВЫЕ ПРОЕКТЫ
                </Text>
              </HStack>
              <HStack>
                <Text color={"white"} width={"30vw"} fontSize={14}>
                  Специализируемся на реализации сложных и нетиповых проектов,
                  где изначально требования до конца не ясны или могут меняться
                  в ходе реализации.
                </Text>
              </HStack>
            </HStack>
          </FadeInWrapper>
        </HStack>
      )}

      {width <= 1456 ? (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
          minH={"90px"}
        >
          <ExpandableComponent
            header={"#3 ЛИЧНЫЙ МЕНЕДЖЕР"}
            text={`При необходимости предоставляется персональный менеджер для
            сбора и формализации требований, а также дизайнер для выполнения
            небольших задач.`}
          />
        </HStack>
      ) : (
        <HStack
          width={"100%"}
          borderTop={"1px solid #373737"}
          borderBottom={"1px solid #373737"}
          padding={"15px 0px 15px 0px"}
        >
          <FadeInWrapper>
            <HStack
              justify={"space-between"}
              width={"85vw"}
              padding={0}
              height={"100px"}
            >
              <HStack>
                <Text color={"#0BCF6B"}>#3</Text>{" "}
              </HStack>
              <HStack>
                <Text color={"white"} fontWeight={700}>
                  ЛИЧНЫЙ МЕНЕДЖЕР
                </Text>
              </HStack>
              <HStack>
                <Text color={"white"} width={"30vw"} fontSize={14}>
                  При необходимости предоставляется персональный менеджер для
                  сбора и формализации требований, а также дизайнер для
                  выполнения небольших задач.
                </Text>
              </HStack>
            </HStack>
          </FadeInWrapper>
        </HStack>
      )}
    </VStack>
  );
};

export default OurPodhod;

import { HStack, VStack, Text, SimpleGrid } from "@chakra-ui/react";
import FadeInWrapper from "./fadein";
import RectangleComponent from "./animated_card";
import useWindowDimensions from "../hooks/window_dimensions";

const OurServes = () => {
  const { width, height } = useWindowDimensions();
  return (
    <VStack width={"100%"} align={"flex-start"} spacing={0}>
      <FadeInWrapper>
        <HStack alignSelf={"flex-start"} marginBottom={"5vh"}>
          <Text color={"#0BCF6B"} fontSize={["25px", "32px" ,"40px"]}>
            НАШИ
          </Text>
          <Text color={"white"} fontSize={["25px", "32px" ,"40px"]}>
            {" "}
            УСЛУГИ
          </Text>
        </HStack>
      </FadeInWrapper>
      <SimpleGrid
        width={"100%"}
        templateColumns={
          width >= 1382
            ? "repeat(3, 1fr)"
            : width >= 900
            ? "repeat(2, 1fr)"
            : "repeat(1, 1fr)"
        }
        gap={"40px"}
        alignContent={'center'}
        alignItems={'center'}
      >
        <RectangleComponent
          header={"Мобильные приложения"}
          text={
            "Создаём мобильные приложения для любых задач, обеспечивая высокую производительность и удобство использования."
          }
          price={150}
        />
        <RectangleComponent
          header={"Веб-сайты"}
          text={
            "Разрабатываем современные и функциональные веб-сайты, которые помогают вашему бизнесу расти."
          }
          price={100}
        />
        <RectangleComponent
          header={"Десктопные приложения"}
          text={
            "Создаём мощные и надёжные десктопные приложения, оптимизированные для любых платформ."
          }
          price={150}
        />
        <RectangleComponent
          header={"Интеграция нейросетей"}
          text={
            "Внедряем нейросетевые решения для автоматизации и улучшения бизнес-процессов."
          }
          price={"~"}
        />
        <RectangleComponent
          header={"Дизайн и макеты"}
          text={
            "Создаём уникальные дизайны и макеты, которые делают ваш продукт привлекательным и удобным."
          }
          price={40}
        />
        <RectangleComponent
          header={"Высоконагруженные приложения с Big Data"}
          text={
            "Разрабатываем сложные и высоконагруженные приложения для обработки и анализа больших данных."
          }
          price={180}
        />
        <RectangleComponent
          header={"IoT решения"}
          text={
            "Создаём комплексные IoT решения для умных устройств и систем, обеспечивая их интеграцию и управление."
          }
          price={100}
        />
        <RectangleComponent
          header={"Telegram Mini Apps"}
          text={
            "Создаём удобные и функциональные мини-приложения для Telegram, обеспечивая быстрый доступ к вашим сервисам и контенту."
          }
          price={150}
        />
        <RectangleComponent
          header={"Telegram боты"}
          text={
            "Разрабатываем ботов для Telegram, автоматизируя взаимодействие с пользователями и улучшая клиентский опыт."
          }
          price={80}
        />
      </SimpleGrid>
    </VStack>
  );
};

export default OurServes;

import { HStack, Text, Link } from "@chakra-ui/react";
import { FaTelegram } from "react-icons/fa";

const Footer = () => {
  return (
    <HStack
      width={"100%"}
      backgroundColor={"black"}
      padding={"15px 8vw"}
      justify={"space-between"}
      minH={"70px"}
      align={"center"}
      marginTop={'80px'}
    >
      <Text color={"#0BDA70"} fontSize={"14px"} fontWeight={800}>
        ©ATLAS IT DEVELOPMENT
      </Text>
      {/* <HStack align={"flex-end"} spacing={"30px"} padding={0}>
        <VStack padding={0}>
          <Text color={'white'}>Контакты</Text>
          <HStack>

          </HStack>
        </VStack>
        
      </HStack> */}
      <Link color={"#0BCF6B"} href="https://t.me/ALLeoon"><FaTelegram size={25}/></Link>
    </HStack>
  );
};
export default Footer;

import {
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import HoverUnderlineText from "./textUnderline";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useWindowDimensions from "../hooks/window_dimensions";

const Header = ({ servesRef, powerRef, podhodRef, projRef, vacancyRef }) => {
  const { width, height } = useWindowDimensions();
  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <HStack
      width={"100vw"}
      justify={"space-between"}
      position={"fixed"}
      top={0}
      backgroundColor={"black"}
      zIndex={2}
      padding={"2vh 8vw"}
    >
      <Text color={"#0BDA70"} fontSize={"28px"} fontWeight={800}>
        ATLAS IT
      </Text>
      {width >= 820 ? (
        <HStack align={"flex-end"} spacing={"30px"}>
          <HoverUnderlineText
            text={"наши услуги"}
            onClick={() => scrollEffect(servesRef)}
          />
          <HoverUnderlineText
            text={"проекты"}
            onClick={() => scrollEffect(projRef)}
          />
          <HoverUnderlineText
            text={"о нас"}
            onClick={() => scrollEffect(powerRef)}
          />
          <HoverUnderlineText
            text={"вакансии"}
            onClick={() => scrollEffect(vacancyRef)}
          />
        </HStack>
      ) : (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            backgroundColor="black"
            color="#0BDA70"
            borderRadius={0}
            border={"1px solid white"}
            _hover={{
              backgroundColor: "#0BDA70",
              color: "white",
            }}
          >
            меню
          </MenuButton>
          <MenuList borderRadius={0} backgroundColor={'black'} color={'#0BDA70'}>
            <MenuItem onClick={() => scrollEffect(servesRef)} backgroundColor={'black'}>
              наши услуги
            </MenuItem>
            <MenuItem onClick={() => scrollEffect(projRef)} backgroundColor={'black'}>проекты</MenuItem>
            <MenuItem onClick={() => scrollEffect(powerRef)} backgroundColor={'black'}>о нас</MenuItem>
            <MenuItem onClick={() => scrollEffect(vacancyRef)} backgroundColor={'black'}>
              вакансии
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};
export default Header;

import { HStack, VStack, Text, SimpleGrid } from "@chakra-ui/react";
import FadeInWrapper from "./fadein";
import RectangleComponent from "./animated_card";
import useWindowDimensions from "../hooks/window_dimensions";

const OurVacancies = () => {
  const { width, height } = useWindowDimensions();
  return (
    <VStack width={"100%"} align={"flex-start"} spacing={0}>
      <FadeInWrapper>
        <HStack alignSelf={"flex-start"} marginBottom={"5vh"}>
          <Text color={"#0BCF6B"} fontSize={["22px", "25px", "32px", "40px"]}>
            НАШИ
          </Text>
          <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
            {" "}
            ВАКАНСИИ
          </Text>
        </HStack>
      </FadeInWrapper>
      <SimpleGrid
        width={"100%"}
        templateColumns={
          width >= 1382
            ? "repeat(3, 1fr)"
            : width >= 900
            ? "repeat(2, 1fr)"
            : "repeat(1, 1fr)"
        }
        gap={"40px"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <RectangleComponent
          header={"Junior Frontend Developer"}
          text={"HTML/CSS, JavaScript (ES6+), React, Git."}
          price={"меньше года"}
          vacancy={true}
        />
        <RectangleComponent
          header={"Middle Frontend Developer"}
          text={"HTML/CSS, JavaScript (ES6+), React, Redux, Git."}
          vacancy={true}
          price={"2-3 года"}
        />
        <RectangleComponent
          header={"Junior Backend Developer"}
          text={"Node.js, Express, MongoDB, SQL, Git."}
          vacancy={true}
          price={"меньше года"}
        />
        <RectangleComponent
          header={"Middle Backend Developer"}
          text={"Node.js, Express, MongoDB, SQL, Docker, Git."}
          vacancy={true}
          price={"2-3 года"}
        />
        <RectangleComponent
          header={"QA Тестировщик"}
          text={"Selenium, JMeter, Postman, TestCaseLab."}
          price={"меньше года"}
          vacancy={true}
        />
      </SimpleGrid>
    </VStack>
  );
};

export default OurVacancies;

import { HStack, VStack, Text, Stack } from "@chakra-ui/react";
import FadeInWrapper from "./fadein";
import useWindowDimensions from "../hooks/window_dimensions";

const OurPower = () => {
  const { width, height } = useWindowDimensions();
  return (
    <VStack width={"100%"} align={"flex-start"} spacing={0}>
      <FadeInWrapper>
        <HStack alignSelf={"flex-start"} marginBottom={"5vh"}>
          <Text color={"#0BCF6B"} fontSize={["25px", "32px" ,"40px"]}>
            НАШИ
          </Text>
          <Text color={"white"} fontSize={["25px", "32px" ,"40px"]}>
            {" "}
            ВОЗМОЖНОСТИ
          </Text>
        </HStack>
      </FadeInWrapper>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
      >
        <FadeInWrapper>
          <Stack direction={width <= 754 ? "column" : "row"}>
            <HStack justify={"flex-start"} width={"20vw"} padding={0}>
              <Text color={"#0BCF6B"}>FRONTEND</Text>{" "}
              <Text color={"white"} fontWeight={700}>
                РАЗРАБОТКА
              </Text>
            </HStack>
            <Text marginLeft={width <= 754 ? "0px" : "20vw"} color={"white"}>
              React / Vue.js / Angular / NodeJS / TypeScript / GraphQL
            </Text>
          </Stack>
        </FadeInWrapper>
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
      >
        <FadeInWrapper>
          <Stack direction={width <= 754 ? "column" : "row"}>
            <HStack justify={"flex-start"} width={"20vw"} padding={0}>
              <Text color={"#0BCF6B"}>MOBILE</Text>{" "}
              <Text color={"white"} fontWeight={700}>
                РАЗРАБОТКА
              </Text>
            </HStack>
            <Text marginLeft={width <= 754 ? "0px" : "20vw"} color={"white"}>
              ReactNative / Swift / Flutter / Kotlin / Java
            </Text>
          </Stack>
        </FadeInWrapper>
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
      >
        <FadeInWrapper>
          <Stack direction={width <= 754 ? "column" : "row"}>
            <HStack justify={"flex-start"} width={"20vw"} padding={0}>
              <Text color={"#0BCF6B"}>QA</Text>{" "}
              <Text color={"white"} fontWeight={700}>
                ТЕСТИРОВАНИЕ
              </Text>
            </HStack>
            <Text marginLeft={width <= 754 ? "0px" : "20vw"} color={"white"}>
              Selenium / JMeter / Postman / SQL / TestCaseLab
            </Text>
          </Stack>
        </FadeInWrapper>
      </HStack>
      <HStack
        width={"100%"}
        borderTop={"1px solid #373737"}
        borderBottom={"1px solid #373737"}
        padding={"15px 0px 15px 0px"}
      >
        <FadeInWrapper>
          <Stack direction={width <= 754 ? "column" : "row"}>
            <HStack justify={"flex-start"} width={"20vw"} padding={0}>
              <Text color={"#0BCF6B"}>BACKEND</Text>{" "}
              <Text color={"white"} fontWeight={700}>
                РАЗРАБОТКА
              </Text>
            </HStack>

            <Text marginLeft={width <= 754 ? "0px" : "20vw"} color={"white"}>
              PHP7 / Python / Jakarta EE / Java SE / Golang / Docker
            </Text>
          </Stack>
        </FadeInWrapper>
      </HStack>
    </VStack>
  );
};

export default OurPower;

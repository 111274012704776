import React, { useState, useEffect, useRef } from 'react';
import './AnimatedText.css';
import useWindowDimensions from '../hooks/window_dimensions';

const AnimatedText = () => {
  const {width, height} = useWindowDimensions()
  const [isVisible, setIsVisible] = useState(false);
  const [showLines, setShowLines] = useState(false);
  const textRef = useRef(null);

  const handleScroll = () => {
    const rect = textRef.current.getBoundingClientRect();
    const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;
    setIsVisible(inView);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isVisible) {
      setShowLines(true);
      setTimeout(() => setShowLines(false), 2000); // Duration of line animation
    }
  }, [isVisible]);

  return (
    <div className="animated-text-container" ref={textRef}>
      {showLines && (
        <>
          <div className="line top-line"></div>
          <div className="line bottom-line"></div>
        </>
      )}
      {isVisible && (
        <div className="text" style={{fontSize: width <= 560 ? '40px' : '60px'}}>
          {'/*ATLAS IT'.split('').map((char, index) => (
            <span key={index} className="char">
              {char}
            </span>
          ))}
          <br />
          {'SOFTWARE ENGINEERING*/'.split('').map((char, index) => (
            <span key={index} className="char delay">
              {char}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnimatedText;

import React from "react";
import "./HoverUnderlineText.css";

const HoverUnderlineText = ({ text, onClick = () => {} }) => {
  return (
    <span className="hover-underline-text" onClick={onClick}>
      {text}
    </span>
  );
};

export default HoverUnderlineText;

import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  useToast,
  VStack,
  Stack,
  Text,
  Link,
  HStack
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import FadeInWrapper from "./fadein";
import useWindowDimensions from "../hooks/window_dimensions";
import { FaTelegram } from "react-icons/fa";

// Validation schema for Formik
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  serviceType: Yup.string(),
  message: Yup.string().required("Required"),
});

const ContactForm = () => {
  const toast = useToast();
  const { width, height } = useWindowDimensions();

  const handleSubmit = async (values, actions) => {
    try {
      // Send email using EmailJS
      const emailParams = {
        user_email: values.email,
        service_type: values.serviceType,
        message: values.message,
      };

      await emailjs.send(
        "service_jq68o18", // Replace with your EmailJS service ID
        "template_giu5x5n", // Replace with your EmailJS template ID
        emailParams,
        "MiWKspT1J6mECejRt" // Replace with your EmailJS user ID
      );

      toast({
        position: "bottom-center",
        render: () => (
          <VStack
            color="white"
            p={3}
            bg="black"
            align={"center"}
            border={"1px solid white"}
          >
            <Text color={"#0BCF6B"} fontFamily={"Roboto Mono"}>
              Сообщение отправлено
            </Text>
          </VStack>
        ),
      });
      actions.resetForm();
    } catch (error) {
      console.error("Error sending email:", error);
      toast({
        title: "Error",
        description: "There was an error sending your message.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Stack
      direction={width <= 880 ? "column" : "row"}
      justify={width <= 880 ?"center" : "flex-start"}
      width={"100%"}
      spacing={width <= 1396 ? (width <= 1056 ? "10vw" : "15vw") : "30vw"}
    >
      <VStack >
        {" "}
        <FadeInWrapper>
          <VStack
            
            marginBottom={"5vh"}
            spacing={0}
            align={width <= 880 ? "flex-start" : "flex-start"}
            gap={0}
            width={width <= 880 ? '80vw' : 'auto'}
          >
            <Text color={"#0BCF6B"} fontSize={["22px", "25px", "32px", "40px"]}>
              ОСТАЛИСЬ ВОПРОСЫ?
            </Text>
            <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
              {" "}
              СВЯЖИТЕСЬ С НАМИ 
            </Text>
            <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
              {" "}
              ЧЕРЕЗ ФОРМУ ИЛИ
            </Text>
            <HStack spacing={'15px'}>
              <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
              {" "}
              В ТЕЛЕГРАМ ={'>'}
            </Text>
            <Link color={"#0BCF6B"} href="https://t.me/ALLeoon"><FaTelegram size={40}/></Link>
            </HStack>
            
          </VStack>
        </FadeInWrapper>
      </VStack>
      <VStack>
        <Formik
          initialValues={{ email: "", serviceType: "", message: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <VStack spacing={4} align="stretch">
                <Field name="email">
                  {({ field }) => (
                    <FormControl isInvalid={errors.email && touched.email}>
                      <FormLabel
                        htmlFor="email"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"#0BCF6B"}
                      >
                        Ваш email
                      </FormLabel>
                      <Input
                        {...field}
                        id="email"
                        placeholder="email"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"white"}
                        borderRadius={0}
                        _focus={{
                          outline: "1px solid #0BCF6B",
                          border: "1px solid #0BCF6B",
                        }}
                      />
                    </FormControl>
                  )}
                </Field>

                <Field name="serviceType">
                  {({ field }) => (
                    <FormControl
                      isInvalid={errors.serviceType && touched.serviceType}
                    >
                      <FormLabel
                        htmlFor="serviceType"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"#0BCF6B"}
                      >
                        Определились с услугой или заинтересовала вакансия?
                      </FormLabel>
                      <Input
                        {...field}
                        id="serviceType"
                        placeholder="Вакансия или услуга"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"white"}
                        borderRadius={0}
                        _focus={{
                          outline: "1px solid #0BCF6B",
                          border: "1px solid #0BCF6B",
                        }}
                      />
                    </FormControl>
                  )}
                </Field>

                <Field name="message">
                  {({ field }) => (
                    <FormControl isInvalid={errors.message && touched.message}>
                      <FormLabel
                        htmlFor="message"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"#0BCF6B"}
                      >
                        Что вы хотите обсудить?
                      </FormLabel>
                      <Textarea
                        {...field}
                        id="message"
                        placeholder="Ваше сообщение"
                        width={width <= 880 ? '80vw' : '350px'}
                        color={"white"}
                        borderRadius={0}
                        _focus={{
                          outline: "1px solid #0BCF6B",
                          border: "1px solid #0BCF6B",
                        }}
                      />
                    </FormControl>
                  )}
                </Field>

                <Button
                  mt={4}
                  isLoading={isSubmitting}
                  type="submit"
                  width={width <= 880 ? '80vw' : '350px'}
                  borderRadius={0}
                  color={"#0BCF6B"}
                  background={"black"}
                  border={"1px solid white"}
                  _hover={{
                    backgroundColor: "#0BCF6B",
                    color: "white",
                    border: "none",
                  }}
                >
                  Отправить
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
        
      </VStack>
    </Stack>
  );
};

export default ContactForm;

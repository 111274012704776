import React, { useState } from "react";
import "./RectangleComponent.css";
import { Text, VStack, HStack } from "@chakra-ui/react";
import useWindowDimensions from "../hooks/window_dimensions";

const RectangleComponent = ({ header, text, price, vacancy = false }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
      
      /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
  };
  const { width, height } = useWindowDimensions();

  return (
    <div
      className={`container ${hovered ? "hovered" : ""}`}
      style={{ width: width <= 424 ? "80vw" : "360px" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => scrollToBottom()}
    >
      <div
        className="red-rectangle"
        style={{ width: width <= 424 ? "40px" : "60px" }}
      >
        <HStack width={"100%"} height={"100%"} justify={"center"}>
          <Text color={"white"} transform={"rotate(270deg)"}>
            ПОДРОБНЕЕ
          </Text>
        </HStack>
      </div>
      <div className="black-rectangle">
        <VStack
          align={"flex-start"}
          justify={"space-between"}
          height={"100%"}
          paddingTop={"10px"}
          paddingBottom={"10px"}
        >
          <VStack align={"flex-start"}>
            <Text color={"#0BCF6B"}>{header}</Text>
            <Text color={"white"} fontSize={14}>
              {text}
            </Text>
          </VStack>

          {!vacancy ? <Text color={"#0BCF6B"}>от {price} т.р</Text> : <Text color={"#0BCF6B"}>Опыт: {price} </Text>}
        </VStack>
      </div>
    </div>
  );
};

export default RectangleComponent;

import { VStack, } from "@chakra-ui/react";
import Header from "./components/header";
import MainImage from "./components/main_image";
import OurPower from "./components/our_power";
import AnimatedText from "./components/animatedengineer";
import OurPodhod from "./components/our_podhod";
import OurProj from "./components/our_projects";
import OurServes from "./components/our_serves";
import Footer from "./components/footer";
import { useRef } from "react";
import ContactForm from "./components/email_form";
import OurVacancies from "./components/our_vacancies";

function App() {
  const servesRef = useRef(null);
  const powerRef = useRef(null);
  const podhodRef = useRef(null);
  const projRef = useRef(null);
  const vacancyRef = useRef(null);
  return (
    <VStack
      width={"100%"}
      minH={"100vh"}
      backgroundImage={
        "linear-gradient(90deg, rgba(93,117,6,1) 0%, rgba(4,79,5,1) 4%, rgba(3,66,4,1) 6%, rgba(22,47,1,1) 10%, rgba(12,33,1,1) 15%, rgba(6,22,1,1) 20%, rgba(1,18,1,1) 25%, rgba(0,0,0,1) 50%, rgba(1,18,1,1) 75%, rgba(6,22,1,1) 80%, rgba(12,33,1,1) 85%, rgba(22,47,1,1) 90%, rgba(3,66,4,1) 94%, rgba(4,79,5,1) 96%, rgba(93,117,6,1) 100%)"
      }
    >
      <Header
        servesRef={servesRef}
        projRef={projRef}
        podhodRef={podhodRef}
        powerRef={powerRef}
        vacancyRef={vacancyRef}
      />
      <VStack padding={"23vh 8vw 5vh 8vw"} spacing={"80px"} width={"100%"}>
        <MainImage />
        <VStack width={"100%"} ref={servesRef}>
          <OurServes />
        </VStack>
        <VStack width={"100%"} ref={powerRef}>
          <OurPower />
        </VStack>

        <VStack height={"500px"} width={"100%"}>
          <AnimatedText />
        </VStack>
        <VStack width={"100%"} ref={podhodRef}>
          <OurPodhod />
        </VStack>
        <VStack ref={projRef} width={"100%"}>
          <OurProj />
        </VStack>
        <VStack width={"100%"} ref={vacancyRef}>
          <OurVacancies />
        </VStack>
        <ContactForm />
        {/* <HStack
          width={"100%"}
          justify={"space-between"}
          align={"flex-start"}
          paddingRight={"6vw"}
        >
          <FadeInWrapper>
            <Text color={"#0BCF6B"} fontSize={["22px", "25px", "32px", "40px"]}>
              ИЛИ НАПИШИТЕ
            </Text>
            <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
              {" "}
              НАШЕМУ МЕНЕДЖЕРУ
            </Text>
          </FadeInWrapper>
          <FadeInWrapper>
            <Text color={"white"} fontSize={["22px", "25px", "32px", "40px"]}>
              {" "}
              https://t.me/ALLeoon
            </Text>
          </FadeInWrapper>
        </HStack> */}
      </VStack>
      <Footer/>
    </VStack>
  );
}

export default App;
